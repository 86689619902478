
  @font-face {
    font-family: 'Archivo-Regular';
    src: url('Archivo-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'Archivo-Medium';
    src: url('Archivo-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'Archivo-Semibold';
    src: url('Archivo-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'Archivo-Bold';
    src: url('Archivo-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }
  
  